<template>
  <div>
    <div class="d-flex justify-content-center col-12 text-center">
      <head-banner />
    </div>
    <div class="d-flex justify-content-center col-12 text-center">
      <add-here />
    </div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <h3 class="mb-2">Token informations</h3>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Token Name"
                    label-for="iv-token-name"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Token Name"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="CpuIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="iv-token-name"
                          v-model="tokenName"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Expl: Bitcoin"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Token Symbol"
                    label-for="iv-token-symbol"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Token Symbol"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="PackageIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="iv-token-symbol"
                          v-model="tokenSymbol"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Expl: BTC"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <!-- Token description start -->
                  <b-form-group
                    label="Token Description"
                    label-for="iv-token-description"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Token Description"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <div id="app">
                          <vue-editor
                            id="iv-token-description"
                            v-model="tokenDescription"
                            :state="errors.length > 0 ? false : null"
                            rows="5"
                            placeholder="Expl: Bitcoin is an innovative payment network and a new kind of money"
                          ></vue-editor>
                        </div>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- Token description end-->
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h3 class="mb-2 mt-2">Presale Token</h3>
                </b-col>
                <b-col md="6">
                  <div class="pb-3">
                    <b-card-text class="mb-0"> Presale </b-card-text>
                    <b-form-checkbox
                      v-model="is_presale"
                      checked="true"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left switch-span">
                        <h4>YES</h4>
                      </span>
                      <span class="switch-icon-right switch-span">
                        <h4>NO</h4>
                      </span>
                    </b-form-checkbox>
                  </div>
                </b-col>
                <!-- Presale Date start -->
                <b-col md="6" v-if="is_presale">
                  <b-form-group
                    label="Presale Date"
                    label-for="iv-released-date"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Released Date"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <b-form-datepicker
                          id="iv-presale-date"
                          v-model="presale_date"
                          class="mb-1"
                          :min="today"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Released Date start end -->
              </b-row>
              <b-row v-if="is_presale">
                <b-col md="6" v-if="is_presale">
                  <b-form-group
                    label="Presale Time"
                    label-for="iv-released-tome"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Released Time"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <b-time
                          id="iv-presale-time"
                          locale="en"
                          v-model="presale_time"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Presale Link"
                    label-for="iv-presale-link"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LinkIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-token-logo-link"
                        v-model="presale_link"
                        type="text"
                        placeholder="Expl: https://.."
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h3 class="mb-2 mt-2">Token Meta Data</h3>
                </b-col>
                <!-- Released Date start -->
                <b-col md="6">
                  <b-form-group
                    label="Released Date"
                    label-for="iv-released-date"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Released Date"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <b-form-datepicker
                          id="iv-released-date"
                          v-model="releasedDate"
                          class="mb-1"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Released Date start end -->
                <!-- Token link start -->
                <b-col md="6">
                  <b-form-group
                    label="Token Logo Link"
                    label-for="iv-token-logo-link"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ImageIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-token-logo-link"
                        v-model="tokenLogoLink"
                        type="text"
                        placeholder="Expl: https://.."
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- Token link start end -->
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Released Time"
                    label-for="iv-released-time"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Released Time"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <b-time
                          id="iv-presale-time"
                          locale="en"
                          v-model="releasedTime"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Token Actual Price"
                        label-for="iv-roken-actual-price"
                        :state="state"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Token Actual Price"
                          rules="required"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                          >
                            <b-input-group-prepend is-text>
                              <feather-icon icon="DollarSignIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="iv-token-actual-price"
                              v-model="tokenActualPrice"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Expl: 0.0002"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Token Actual Market Cap"
                        label-for="iv-token-actual-market-cap"
                        :state="state"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Token Actual Market Cap"
                          rules="required"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                          >
                            <b-input-group-prepend is-text>
                              <feather-icon icon="TrendingUpIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="iv-token-actual-market-cap"
                              v-model="tokenActualMarketCap"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Expl: 5200000"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <div class="pb-3">
                    <b-card-text class="mb-0 pb-1"> Doxxed </b-card-text>
                    <b-form-checkbox
                      v-model="is_doxxed"
                      checked="true"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left switch-span">
                        <h4>YES</h4>
                      </span>
                      <span class="switch-icon-right switch-span">
                        <h4>NO</h4>
                      </span>
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Coin Market cap"
                    label-for="iv-coin-market-cap"
                    :state="state"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="TrendingUpIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-coin-market-cap"
                        v-model="coinmarketcap"
                        type="text"
                        placeholder="Expl: https://coinmarketcap.com/currencies/"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Coin Gecko"
                    label-for="iv-coin-gecko"
                    :state="state"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="CrosshairIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-coin-gecko"
                        v-model="coingecko"
                        type="text"
                        placeholder="Expl: https://www.coingecko.com/en/coins/"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12" class="mt-3">
              <h3 class="mb-2">Smart Contracts</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              
              <b-row>
                <b-col md="12">
                  <!-- Token Binance Smart Chain contract address start -->
                  <b-form-group
                    label="Token Binance Smart Chain contract address"
                    label-for="iv-bsc-address"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PackageIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-bsc-address"
                        v-model="bscAddress"
                        type="text"
                        placeholder="Expl: 0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- Token Binance Smart Chain contract address end-->
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <!-- Token Ethereum contract address start -->
                  <b-form-group
                    label="Token Ethereum contract address"
                    label-for="iv-eth-address"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PackageIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-eth-address"
                        v-model="ethAddress"
                        type="text"
                        placeholder="Expl: 0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- Token Ethereum contract address end-->
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <!-- Token TRON contract address start -->
                  <b-form-group
                    label="Token TRON contract address"
                    label-for="iv-trx-address"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PackageIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-trx-address"
                        v-model="tron_token"
                        type="text"
                        placeholder="Expl: 0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- Token TRON contract address end-->
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <!-- Other links start -->
                  <b-form-group
                    label="Other links, other blockchain contracts or anything else you would like to add to your coin request"
                    label-for="iv-other-links"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-textarea
                        id="iv-other-links"
                        v-model="otherLinks"
                        rows="5"
                        placeholder="Expl: Bitcoin is an innovative payment network and a new kind of money"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- Other links end-->
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="12">
                  <!-- Token KCC contract address start -->
                  <b-form-group
                    label="Token KCC contract address"
                    label-for="iv-kcc-address"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PackageIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-kcc-address"
                        v-model="kcc_token"
                        type="text"
                        placeholder="Expl: 0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- Token KCC contract address end-->
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <!-- Token Solana contract address start -->
                  <b-form-group
                    label="Token Solana contract address"
                    label-for="iv-sol-address"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PackageIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-sol-address"
                        v-model="solana_token"
                        type="text"
                        placeholder="Expl: 0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- Token Solana contract address end-->
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <!-- Token Polygon contract address start -->
                  <b-form-group
                    label="Token Polygon contract address"
                    label-for="iv-polygon-address"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PackageIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-polygon-address"
                        v-model="polygon_token"
                        type="text"
                        placeholder="Expl: 0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- Token Polygon contract address end-->
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <!-- Token Avalanche contract address start -->
                  <b-form-group
                    label="Token Avalanche contract address"
                    label-for="iv-avalanche-address"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PackageIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-avalanche-address"
                        v-model="avalanche_token"
                        type="text"
                        placeholder="Expl: 0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- Token Avalanche contract address end-->
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
                <h3 class="mb-2 mt-2">Social</h3>
              </b-col>
          </b-row>

           <b-row>
            <b-col md="6">
              
               <b-row>
                <b-col md="6">
                  <b-form-group label="Website" label-for="iv-website">
                    <validation-provider
                      #default="{ errors }"
                      name="Website"
                      rules="required|url"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="iv-website"
                          v-model="website"
                          type="text"
                          placeholder="Expl: https://www.bitcoin.org"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Telegram" label-for="iv-telegram">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MessageSquareIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="iv-telegram"
                        v-model="telegram"
                        type="text"
                        placeholder="Expl: https://t.me/bitcoin"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

              <b-col md="6">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Twitter" label-for="iv-twitter">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="TwitterIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="iv-twitter"
                          v-model="twitter"
                          type="text"
                          placeholder="Expl: https://twitter.com/bitcoin"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Reddit" label-for="iv-reddit">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="BellIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="iv-reddit"
                          v-model="reddit"
                          type="text"
                          placeholder="Expl: https://reddit.com/bitcoin"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Discord" label-for="iv-discord">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="PhoneCallIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="iv-discord"
                          v-model="discord"
                          type="text"
                          placeholder="Expl: https://discord.com/bitcoin"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
          </b-row>
          
          <b-col v-if="loading" cols="12" class="text-center">
            <b-spinner class="mt-2" variant="primary" />
          </b-col>
          <b-col v-else cols="12" class="text-center">
            <b-button
              class="mt-2"
              variant="primary"
              type="submit"
              loading="true"
              @click.prevent="validationForm"
            >
              Submit Request
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import HeadBanner from "@/@core/components/carousel/HeadBanner.vue";
import AddHere from "@/@core/components/AddHere.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, url } from "@validations";
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BFormDatepicker,
  BSpinner,
  BCardText,
  BFormCheckbox,
  BTime,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FormData from "form-data";
import API from "../../services/api";
import { VueEditor } from "vue2-editor";
import { mixinList } from "@/mixins/mixinList";

export default {
  mixins: [mixinList],
  components: {
    VueEditor,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BSpinner,
    BFormCheckbox,
    BCardText,
    AddHere,
    HeadBanner,
    BTime,
  },
  computed: {
    tokenNameValidation() {
      return this.tokenName.length >= 4;
    },
    tokenSymbolValidation() {
      return this.tokenSymbol.length >= 2;
    },
    tokenDescriptionlValidation() {
      return this.tokenDescription.length >= 50;
    },
  },
  data() {
    return {
      loading: false,
      state: "",
      required,
      url,
      tokenName: "",
      tokenSymbol: "",
      tokenDescription: "",
      tokenActualPrice: "",
      releasedDate: "",
      releasedTime: "",
      tokenLogoLink: "",
      bscAddress: "",
      ethAddress: "",
      tron_token: "",
      kcc_token: "",
      solana_token: "",
      polygon_token: "",
      avalanche_token: "",
      tokenActualMarketCap: "",
      coingecko: "",
      coinmarketcap: "",
      otherLinks: "",
      website: "https://",
      telegram: "",
      twitter: "",
      reddit: "",
      discord: "",
      is_doxxed: false,
      is_presale: false,
      presale_date: "",
      presale_time: "",
      presale_link: "",
      today: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.submitFormData();
        }
      });
    },

    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      });
    },

    submitFormData() {
      const user_data = localStorage.getItem("profile");
      const user_id = JSON.parse(user_data).id;
      let doxxed_value = 0;
      if (this.is_doxxed) {
        doxxed_value = 1;
      } else {
        doxxed_value = 0;
      }
      let is_presale = 0;
      if (this.is_presale) {
        is_presale = 1;
      } else {
        is_presale = 0;
      }
      let presaleTime = this.presale_date + " " + this.presale_time;
      let released = this.releasedDate + " " + this.releasedTime;

      let formData = new FormData();

      formData.append("name", this.tokenName);
      formData.append("symbol", this.tokenSymbol);
      formData.append("description", this.tokenDescription);
      formData.append("telegram_link", this.telegram);
      formData.append("logo_link", this.tokenLogoLink);
      formData.append("website", this.website);
      formData.append("twitter_link", this.twitter);
      formData.append("reddit_link", this.reddit);
      formData.append("discord_link", this.discord);
      formData.append("bsc_contract_address", this.bscAddress);
      formData.append("ethereum_contract_address", this.ethAddress);
      formData.append("tron_token", this.tron_token);
      formData.append("kcc_token", this.kcc_token);
      formData.append("solana_token", this.solana_token);
      formData.append("polygon_token", this.polygon_token);
      formData.append("avalanche_token", this.avalanche_token);
      formData.append("other_links", this.otherLinks);
      formData.append("actual_price", this.tokenActualPrice);
      formData.append("actual_market_cap", this.tokenActualMarketCap);
      formData.append("coingecko", this.coingecko);
      formData.append("coinmarketcap", this.coinmarketcap);
      formData.append("release_date", released);
      formData.append("presale_date", presaleTime);
      formData.append("user_id", user_id);
      formData.append("is_doxxed", doxxed_value);
      formData.append("is_presale", is_presale);
      formData.append("presale_link", this.presale_link);

      API.post("/coin", formData)
        .then((response) => {
          if (response.status == 201) {
            //make the success toast message and rest the data fields
            this.makeToast(
              "success",
              "Congratulations 🏆",
              "Your coin has been submitted"
            );
          }
          this.$router.push("/profile");
          //reset the form
          this.resetFormData();
        })
        .catch((error) => {
          //check for validation errors
          if (error.response.status == 422) {
            let errorList = error.response.data.errors || [];
            const keys = Object.keys(errorList);
            if (keys.length > 0) {
              keys.forEach((key) => {
                this.makeToast("danger", errorList[key][0], "Validation Error");
              });
            }
          } else {
            this.makeToast(
              "danger",
              "Something went wrong",
              "Please Try Again"
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetFormData() {
      this.tokenName =
        this.tokenSymbol =
        this.tokenDescription =
        this.releasedDate =
        this.tokenLogoLink =
        this.releasedTime =
          null;
      this.tokenActualPrice =
        this.tokenActualMarketCap =
        this.coingecko =
        this.coinmarketcap =
        this.bscAddress =
        this.ethAddress =
        this.tron_token =
        this.kcc_token =
        this.solana_token =
        this.polygon_token =
        this.avalanche_token =
          null;
      this.otherLinks =
        this.website =
        this.telegram =
        this.twitter =
        this.reddit =
        this.discord =
        this.presale_link =
        this.presale_date =
        this.presale_time =
          null;
      this.$refs.simpleRules.reset();
    },
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
.custom-switch .custom-control-label::after {
  top: calc(0.225rem + 10px);
  width: 1rem;
  height: 1rem;
  margin-left: 2.7rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border-color 0s;
}
.custom-switch .custom-control-label::before {
  height: 2.7rem;
  width: 5.7rem;
  transition: opacity 0.25s ease, background-color 0.1s ease;
  user-select: none;
}
.switch-span {
  margin-top: 10px;
}
</style>
